<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Información <br>personal
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Entidades
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Formulario <br>entidad
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Resumen <br>crédito
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de <br>documentos
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>

            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Información personal
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" class="scroll-container pt-0">

              <v-row justify="center" class="mt-0">
                <v-col cols="12" class="mt-0 py-5 px-10">

                  <v-card color="lighten-5 px-6" elevation="3">

                    <v-row justify="center" class="mt-0 pb-5 d-flex align-center ">
                      <v-col cols="12" sm="12" md="5" lg="5" class="mt-0 py-1 px-1">

                        <v-avatar size="80%" style="color: #EEEEEE; border: 3px solid;">
                          <v-img
                              src="https://app-biocredit.s3.amazonaws.com/Entities/COOPCENTRAL/LOGO_COOPCENTRAL_400X400.png"
                          ></v-img>
                        </v-avatar>

                      </v-col>

                      <v-col cols="12" sm="12" md="7"  lg="7" class="mt-0 pt-4 px-1">

                        <div class="my-0 pt-0 mt-4 primary--text">
                          <h3 class="text-center today-regular line-height-1 text-title">BANCO COOPCENTRAL</h3>

                          <v-row justify="center" class="mt-3">
                            <v-col cols="12" md="10" lg="10">
                              <ul class="black--text">
                                <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Tasa de interes: Desde 1.06% hasta 2.25%</h3></li>
                                <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Plazo para tasa fija: Entre 12 y 60 meses</h3></li>
                                <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Edad entre: 18 y 84</h3></li>
                                <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Desembolso desde: $ 1.000.000</h3></li>
                                <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Ingresos del deudor: Mínimo 1 SMMLV</h3></li>
                              </ul>
                            </v-col>
                          </v-row>

                        </div>



                      </v-col>

                      <v-layout justify-center pt-5 class="">
                        <v-card-actions>
                          <v-btn
                              :loading="loading"
                              :disabled="loading"
                              @click="goFormEntity"
                              color="primary"
                              class="px-12 text-capitalize vardi-button"
                              elevation="0">
                            Solicitar
                          </v-btn>
                        </v-card-actions>
                      </v-layout>

                    </v-row>

                  </v-card>



                </v-col>
              </v-row>




            </v-col>
          </v-row>



        </v-container>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";

export default {
  name: 'Entities',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      dialog : false,
      user: null,
      name: '',
      gender: '',
      policies: [],
      loading: false,
    }
  },
  created() {
    /*api.get(constants.endPoints.policies, true)
        .then(response => {
          this.policies = response.data.data;


          console.log(this.policies);
        });*/
  },
  mounted() {

    /*this.user = locals.getLocal('user');
    if(!this.dialog) {
      api.get(constants.endPoints.user, true)
          .then(response => {

            this.name = response.data.first_name;
            this.gender = response.data.gender;
            this.user.name = this.name;
            this.user.first_name = response.data.first_name;
            this.user.last_name = response.data.last_name;
            locals.setLocal('user', JSON.stringify(this.user));

            this.dialog = true;
            console.log(response);
          })
    }*/
  },
  methods: {
    goFormEntity() {
      this.$router.push('/formulario-entidad')
    },
    goSimulator(policyId) {
      this.$router.push({
        name: 'Simulator',
        params: { insurancePolicyId: policyId}
      });
    },
    validatePolicyStatus(policy) {
      console.log('validatePolicyStatus', policy.values[3].value.search('Pagada'));
      return policy.values[3].value.search('Pagado') != -1;
    }
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #FF9800 !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #FF9800 !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #FF9800 !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}
</style>
