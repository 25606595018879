<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Información <br>personal
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Entidades
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Formulario <br>entidad
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Resumen <br>crédito
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de <br>documentos
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>

            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Simulador
              </div>
            </v-col>

          </v-row>

          <v-row v-if="simulatorStatus" justify="center" class="px-0">
            <v-col cols="12" sm="6" md="5" lg="4">

              <div class="px-2 pb-6">
                <v-form
                    ref="form"
                    lazy-validation>

                  <currency-input :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                  }"
                                  :label="'Valor del producto'"/>

                  <currency-input v-if="discountValue > 0" :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                  }"
                                  :label="'Descuento'"
                                  :value="discountValue + ''"
                                  :disabled="true"/>

                  <currency-input v-if="valueFinance > 0" :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: false,
                                    autoDecimalDigits: false,
                                    autoSign: false,
                                    useGrouping: true,
                                    accountingSign: true
                                  }"
                                  :label="'Valor a financiar'"
                                  :value="valueFinance + ''"
                                  :disabled="true"/>

                  <v-row class="align-center">
                    <v-col cols="7" sm="10" md="7">
                      <v-select
                          :items="items"
                          :rules="[v => !!v || 'Selecciona el campo']"
                          label="Cuotas"
                          required
                          outlined
                          dense
                          color="primary"
                          v-model="feeNumber"
                          v-on:change="calculateFee"
                          class="select-field select-vardi"
                      ></v-select>
                    </v-col>
                    <v-col cols="5" md="2">

                    </v-col>
                  </v-row>

                  <p class="text-caption today-light">Mín {{ minFeeNumber }} {{ typeFeeNumber }} - Máx {{ maxFeeNumber }}
                    {{ typeFeeNumber }}</p>

                  <h3 class="today-medium">Tasa de interés (N.M.V.)</h3>
                  <h2 class="primary--text today-regular">Desde {{ rate }}% hasta 2.2%</h2>
                  <small class="my-0 py-0">*Sujeto a políticas de aprobación</small>

                </v-form>
              </div>

            </v-col>
            <v-col cols="12" md="7" lg="6">

              <v-card class="mx-auto px-1 pb-4"
                      max-width="90%"
                      outlined
                      style="border-color: #ffd100; border-radius: 12px;">

                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-title>
                      <h2 class="primary--text wrap-text today-regular" style="font-size: 1.3em;">Valor primera cuota</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="primary--text today-regular">$ {{ formatCurrency(firstFeeValue) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-list class="transparent mt-0 mb-5">
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 wrap-text today-light">Cuota amortizada</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(amortizedFee) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 wrap-text today-light">Estudio de crédito</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(creditStudy) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 today-light wrap-text">Seguro</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(insurance) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 today-light">IVA</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(ivaValue) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-row justify="center">
                  <v-col cols="8">
                    <v-btn
                        color="white"
                        class="text-capitalize mt-2 primary--text today-medium"
                        block
                        @click="payDialog = true"
                    style="font-size: 1rem;">
                      Plan de pago
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mt-0">
                  <v-col cols="8">
                    <v-btn
                        :loading="loadingFinance"
                        :disabled="loadingFinance"
                        color="primary"
                        class="text-capitalize today-medium"
                        elevation="0"
                        block
                        @click="registerCellphone"
                        style="font-size: 1rem;">
                      Financiar
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

          <v-row v-if="!simulatorStatus">
            <v-col cols="12">
              <h2 class="primary--text text-center mb-0 today-light line-height-1">{{ simulatorMessage }}</h2>
            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>

    <v-dialog
        v-model="payDialog"
        max-width="400"
        overlay-color="black"
    >
      <v-card class="pa-4 text-center">

        <h2 class="my-3 today-medium justify-center primary--text">PLAN DE PAGO</h2>

        <v-simple-table dense
                        fixed-header
                        >
          <template v-slot:default class="table-striped">
            <thead>
              <tr >
                <th class="text-center" style="background-color: rgba(0, 0, 0, .05);">
                  Descripción
                </th>
                <th class="text-center" style="background-color: rgba(0, 0, 0, .05);">
                  Valor
                </th>
                <th class="text-center" style="background-color: rgba(0, 0, 0, .05);">
                  Fecha
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                  v-for="item in payPlan"
                  :key="item.name" >
                <td>{{ item.description }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 text-capitalize today-bold"
              @click="payDialog = false"
              elevation="0"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="registerCellphoneDialog"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/phone.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">REGISTRA TU CELULAR</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">
          Te enviaremos vía SMS un código de verificación al número de celular que registres
        </h3>

        <div class="px-10 mt-6" v-if="!codeSent">
          <v-form
              ref="form"
              lazy-validation>

            <v-text-field
                v-model="cellphone"
                :rules="[rules.required, rules.counterMax, rules.counterMin]"
                type="number"
                label="Celular"
                maxlength="10"
                outlined
                dense
                color="primary"
                class="input-field"></v-text-field>

            <v-layout justify-center>
              <v-card-actions>
                <v-btn
                    v-if="!codeSent"
                    :loading="loadingCellphone"
                    :disabled="loadingCellphone"
                    color="primary"
                    class="px-10 mt-0 text-capitalize today-bold vardi-button"
                    @click="sendCellphone"
                    elevation="0"
                >
                  Enviar Número
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

        <div class="px-4 mt-2" v-if="codeSent">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="text"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"></v-text-field>
              </v-col>

            </v-row>

            <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

            <p class="primary--text text-center" v-if="reSendCode">
              <a href="#" @click="sendCellphone()" class="text-center today-regular">Reenviar código</a>
            </p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">

          <v-btn
              v-if="codeSent"
              :loading="loadingCellphone"
              :disabled="loadingCellphone"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCode"
              elevation="0"
          >
            Enviar Código
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  name: 'Policy',
  components: {LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      cellphone: '',
      payDialog: false,
      registerCellphoneDialog: false,
      loadingCellphone: false,
      loadingFinance: false,
      codeSent: false,
      items: [2],
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      creditInsurance: 0,
      ivaValue: 0,
      iva: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 0,
      totalValuePolicy: 0,
      discountValue: 0,
      valueFinance: 0,
      feeNumber: 2,
      minFeeNumber: 0,
      maxFeeNumber: 0,
      typeFeeNumber: '',
      rate: 0,
      rateType: '',
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      request: null,
      insurancePolicyId: null,
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      simulatorStatus: true,
      simulatorMessage: '',
      payPlan: [],
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      reSendCode: false,
      testValuePolicy: '129991'
    }
  },
  mounted() {
  },
  methods: {
    registerCellphone() {

      this.$router.push('/informacion-personal')

      /*this.loadingFinance = true;

      console.log(this.insurancePolicyId);

      api.get(constants.endPoints.phoneIsRegistered, true)
          .then(response => {

            let dataResponse = response.data;

            console.log('phoneIsRegistered', dataResponse);
            if(dataResponse.status) {
              this.createRequest();
            } else {
              this.registerCellphoneDialog = true;
            }

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))*/


      // SE MUESTRA EL MODAL SI NO ESTA REGISTRADO EL CELULAR
      //this.registerCellphoneDialog = true;
    },
    sendCellphone: function () {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loadingCellphone = true;

        api.post(constants.endPoints.registerPhone, {
          cellphone: this.cellphone
        }, true)
            .then((response) => {
              console.log(response);
              this.request.id = response.data.data.id;

              // Se inicia la cuenta atras
              this.startCountDown();
              this.loadingCellphone = false;
              this.codeSent = true;

            }, (error) => {
              console.log('post', error);
            });

      }
    },
    sendValidateCode: function () {
      this.loadingCellphone = true;

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      console.log('token', token);

      if(token.length == 6) {
        api.post(constants.endPoints.validatePhone, {
          cellphone: this.cellphone,
          token: token
        }, true)
            .then((response) => {
              console.log(response);
              let dataResponse = response.data;
              this.loadingCellphone = false;

              if(dataResponse.status) {
                this.createRequest();
              } else {
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }

            }, (error) => {
              console.log('post', error);
            });
      } else {
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }


    },
    createRequest() {
      api.post(constants.endPoints.createRequest, {
      }, true)
          .then((response) => {
            console.log('createRequest', response);
            this.loadingFinance = false;
            this.request.id = response.data.data.id;
            locals.setLocal('request', JSON.stringify(this.request));

            //this.$router.push('/formulario');
          }, (error) => {
            console.log('post', error);
          });
    },
    calculateFee() {

      // NUEVA ECUACION
      //$format_rate = (float)$rate / 100;
      //$value = ($amount / (1 + $format_rate)) * ((pow(1 + $format_rate, $number_installments) * $format_rate) / ((pow(1 + $format_rate, $number_installments) - 1)));
      // return number_format($value, 0);

      this.valueFinance = parseInt(this.valueFinance, 10);

      let format_rate = this.rate / 100;
      this.feeValue = (this.valueFinance / (1 + format_rate)) * ((Math.pow(1 + format_rate, this.feeNumber) * format_rate) / ((Math.pow(1 + format_rate, this.feeNumber) - 1)));


      /*this.feeValue = _rate / (1 - Math.pow(1 + _rate, -this.feeNumber));
      this.feeValue = this.feeValue * this.valueFinance;

      this.insurance = this.valueFinance * (this.creditInsurance / 100)

      // VALIDAR SI EL IVA SE SACA DE ESTOS VALORES
      this.ivaValue = this.insurance * (this.iva / 100);*/

      this.amortizedFee = this.feeValue;
      this.firstFeeValue = this.amortizedFee + this.creditStudy + this.insurance + this.ivaValue;
      this.othersFeeValue = this.amortizedFee + this.admin;

      this.calculatePaymentPlan();

    },
    calculatePaymentPlan: function() {
      this.payPlan = [];

      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();

      let paymentDays = constants.paymentDays;
      paymentDays.forEach((value) => {
        if (day > value.minDay && day < value.maxDay) {
          day = value.day;
        }
      });

      for (let i = 1; i <= this.feeNumber; i++) {
        if(month == 12) {
          month = 1;
        } else {
          month += 1;
        }
        let dataPayment = {
          description: 'Pago cuota ' + i,
          value: '$ ' + (i == 1 ? this.formatCurrency(this.firstFeeValue) : this.formatCurrency(this.othersFeeValue)),
          date: (i == 1 ? date.getDate() : day) + '/' + month + '/' + year,
        };
        this.payPlan.push(dataPayment);
      }
    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0
      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.countdown = this.expires_in;
      this.interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(this.interval);
          this.countdown = this.expires_in;
          this.reSendCode = true;
          //this.reSendSignatureOtp();
        } else {
          this.countdown -= 1;
          this.reSendCode = false;
        }
      }, 1000);
    },
    goNext(event){
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    },
    handleActivationInput(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      if (this.activationKeyFields[index].value.length > maxlength) {
        event.preventDefault();
        this.activationKeyFields[index].value = value.slice(0, 6);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {}
        return;
      }

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          event.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        event.preventDefault();
      }
      console.log(event, index, maxlength, this.activationKeyFields);

    },
    getSimulatorService(insurancePolicyId) {
      this.insurancePolicyId = insurancePolicyId;
      api.get(constants.endPoints.simulator + '?insurance_policy_id=' + insurancePolicyId, true)
          .then(response => {
            console.log('getSimulatorService', response);
            let simulator = response.data.data;
            this.simulatorStatus = response.data.status;
            this.simulatorMessage = response.data.message;

            if(this.simulatorStatus) {
              this.discountValue = simulator.discount;
              this.totalValuePolicy = simulator.total;
              this.valueFinance = parseInt(simulator.total_final, 10);
              this.rate = simulator.rate;
              this.rateType = simulator.rate_type;
              this.creditStudy = parseInt(simulator.credit_study, 10);
              this.admin = parseInt(simulator.administration, 10);
              this.creditInsurance = parseInt(simulator.credit_insurance, 10);
              this.iva = simulator.iva;

              this.minFeeNumber = simulator.min_fee_number;
              this.maxFeeNumber = simulator.max_fee_number;
              for (let i = parseInt(this.minFeeNumber); i <= parseInt(this.maxFeeNumber); i++) {
                this.items.push(i);
              }
              this.typeFeeNumber = simulator.type_fee_number;

              this.calculateFee();
            }

          });
    },
    reSendSignatureOtp() {
      api.post(constants.endPoints.reSendSignatureOtp, {
        user_request_id: this.request.id,
      }, true)
          .then(response => {
            let dataResponse = response.data.data;

            this.snackbarMessage = dataResponse.otp.message;
            this.snackbar = true;

            this.startCountDown();

            console.log('reSendSignatureOtp', dataResponse);

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))
    }
  },
  created: function() {

    this.insurancePolicyId = this.$route.params.insurancePolicyId;
    console.log(this.insurancePolicyId);
    this.request = {};
    if(this.insurancePolicyId != null) {
      this.request.insurance_policy_id = this.insurancePolicyId;
      locals.setLocal('request', JSON.stringify(this.request));
      //this.getSimulatorService(this.request.insurance_policy_id);
    } else {
      this.request = locals.getLocal('request');
      if(this.request) {
        //this.getSimulatorService(this.request.insurance_policy_id);
      } else {
        //this.$router.go(-1);
      }
    }

    console.log('Params: ', this.$route.params);
  },
  computed: {
    activationKey() {
      let value = '';
      for (let field of this.activationKeyFields) {
        value += field.value;
      }
      return value;
    }
  },
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #FF9800 !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #FF9800 !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #FF9800 !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}
</style>
